import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { manageSiteSettings } from "src/actions/site";
import { toast } from "react-toastify";
import Input from "src/components/Shared/Forms/Inputs/Input";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";

const ActivitySettings = ({ site, manageSiteSettings }) => {
  const [activityWarningEnable, setActivityWarningEnable] = useState(site?.activity_warning_enable || false);
  const [activityWarningView, setActivityWarningView] = useState(site?.activity_warning_view || "");
  const [activityWarningMessage, setActivityWarningMessage] = useState(site?.activity_warning_message || "");
  const [activityLockoutEnable, setActivityLockoutEnable] = useState(site?.activity_lockout_enable || false);
  const [activityLockoutView, setActivityLockoutView] = useState(site?.activity_lockout_view || "");
  const [activityLockoutMinute, setActivityLockoutMinute] = useState(site?.activity_lockout_minute || "");
  const [activityLockoutMessage, setActivityLockoutMessage] = useState(site?.activity_lockout_message || "");
  const [disabled, setDisabled] = useState(false);

  const setPageData = () => {
    if (site?._id) {
      setActivityWarningEnable(site.activity_warning_enable || false);
      setActivityWarningView(site.activity_warning_view || "");
      setActivityWarningMessage(site.activity_warning_message || "");
      setActivityLockoutEnable(site.activity_lockout_enable || false);
      setActivityLockoutView(site.activity_lockout_view || "");
      setActivityLockoutMinute(site.activity_lockout_minute || "");
      setActivityLockoutMessage(site.activity_lockout_message || "");
    }
  };

  const onClear = () => {
    setPageData();
  };

  useEffect(() => {
    setPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const onSubmit = async () => {
    try {
      setDisabled(true);
      const message = await manageSiteSettings({
        activity_warning_enable: activityWarningEnable,
        activity_warning_view: activityWarningView,
        activity_warning_message: activityWarningMessage,
        activity_lockout_enable: activityLockoutEnable,
        activity_lockout_view: activityLockoutView,
        activity_lockout_minute: activityLockoutMinute,
        activity_lockout_message: activityLockoutMessage,
      });
      setDisabled(false);
      toast.success(message);
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  return (
    <EditContainer
      title="Activity Settings"
      subtitle="Manage your all activity settings from here"
      preview={
        <div className="flex h-10 w-56 items-center">
          <div className="flex gap-x-2 rounded border border-gray-100 px-3 py-1 shadow">Anti-Automation / Abuse Prevention</div>
        </div>
      }
      isLoading={disabled}
      onSuccess={onSubmit}
      onCancel={onClear}
      defaultOptions={{
        onCancelButtonVisible: true,
        onSuccessButtonVisible: true,
      }}
      fullWidth={true}>
      <>
        <div className="relative">
          <h3 className="mb-4 mt-3 pb-1 text-xl font-semibold text-gray-500">
            Anti-Automation / Abuse Prevention <span className="font-normal text-gray-300">(BETA)</span>
          </h3>
          <div className="relative mb-5 w-full space-y-20">
            <div className="relative w-full">
              <div className="mb-4 flex gap-x-5 font-medium text-gray-500">
                <ToggleHeader
                  title="Initial warning"
                  subtitle='Warn users of "malicious bot-like" behavior'>
                  <Toggle
                    checked={activityWarningEnable}
                    onChange={setActivityWarningEnable}
                  />
                </ToggleHeader>
              </div>
              <div className="flex w-full flex-col gap-x-5 gap-y-3 sm:px-8">
                <div className="w-[150px]">
                  <Input
                    disabled={!activityWarningEnable}
                    name="views"
                    label="Max views (per min.)"
                    inline={true}
                    value={activityWarningView}
                    onChange={(e) => setActivityWarningView(e.target.value)}
                  />
                </div>
                <div className="w-full max-w-xl">
                  <TextArea
                    disabled={!activityWarningEnable}
                    name="message"
                    label="Warning message"
                    inline={true}
                    value={activityWarningMessage}
                    onChange={(e) => setActivityWarningMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="relative w-full">
              <div className="mb-4 flex gap-x-5 font-medium text-gray-500">
                <ToggleHeader
                  title="Temporary lockout"
                  subtitle="Lock out abusive users.">
                  <Toggle
                    checked={activityLockoutEnable}
                    onChange={setActivityLockoutEnable}
                  />
                </ToggleHeader>
              </div>
              <div className="flex w-full flex-col gap-x-5 gap-y-3 sm:px-8">
                <div className="flex gap-x-4">
                  <div className="w-[150px]">
                    <Input
                      disabled={!activityLockoutEnable}
                      name="views"
                      label="Max views (per min.)"
                      inline={true}
                      value={activityLockoutView}
                      onChange={(e) => setActivityLockoutView(e.target.value)}
                    />
                  </div>
                  <div className="w-[150px]">
                    <Input
                      disabled={!activityLockoutEnable}
                      name="locked_min"
                      label="Lockout clock (min.)"
                      inline={true}
                      value={activityLockoutMinute}
                      onChange={(e) => setActivityLockoutMinute(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full max-w-xl">
                  <TextArea
                    disabled={!activityLockoutEnable}
                    name="message"
                    label="Lockout message"
                    inline={true}
                    value={activityLockoutMessage}
                    onChange={(e) => setActivityLockoutMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(ActivitySettings);
