export const groupBy = (xs, key) =>
  xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const onlyUnique = (value, index, array) => array.indexOf(value) === index;

export const objectToFormData = (obj, formData = new FormData(), namespace = "") => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let propName = namespace ? `${namespace}[${key}]` : key;
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        if (obj[key] instanceof File) {
          formData.append(propName, obj[key]);
        } else {
          objectToFormData(obj[key], formData, propName);
        }
      } else {
        formData.append(propName, obj[key]);
      }
    }
  }
  return formData;
};

export const isSVGString = (str) => {
  const svgRegex = /<svg(.*?)<\/svg>/i;
  return svgRegex.test(str);
};

export const generateLink = (domain) => {
  return domain ? `https://${domain}` : "";
};

export const getBaseFromDomain = (domain) => {
  return domain ? domain.replace("https://", "").replace("http://", "") : "";
};

export const getMinuteDifference = (date1, date2) => {
  const diffInMs = Math.abs(date2 - date1);
  const minutes = Math.floor(diffInMs / (1000 * 60));
  return minutes;
};

export function extractAndRemoveScripts(htmlDoc) {
  // Create a new DOM parser
  const parser = new DOMParser();
  // Parse the HTML document
  const doc = parser.parseFromString(htmlDoc, "text/html");

  // Get all script elements
  const scripts = doc.getElementsByTagName("script");
  const extractedScripts = [];

  // Iterate through script tags in reverse to avoid live NodeList issues
  for (let i = scripts.length - 1; i >= 0; i--) {
    // Add script outerHTML to the array
    extractedScripts.push(scripts[i].outerHTML);
    // Remove the script element from the document
    scripts[i].parentNode.removeChild(scripts[i]);
  }

  // Serialize the document back to a string
  const serializer = new XMLSerializer();
  const modifiedHtml = serializer.serializeToString(doc);

  return { extractedScripts, modifiedHtml };
}

export const transpileES6ToES5 = (code) => {
  // Regular expression to match ES6 let and const declarations
  const letConstRegex = /\b(let|const)\s+/g;

  // Replace let and const with var
  const transpiledCode = code.replace(letConstRegex, "var ");

  return transpiledCode;
};

export const formatCurrency = (amount, currency = "USD", locale = "en-US") => {
  // Create a number formatter instance based on locale and currency code
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2, // Ensure 2 decimal places
  });

  // Format the amount using the formatter
  return formatter.format(amount);
};

export const allowedScriptTypes = ["text/javascript", "application/javascript", "application/ecmascript"];
