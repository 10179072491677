import React from "react";
// import { Chart } from "@domoinc/domo-phoenix";
// import axios from "axios";
// import { apiRequest } from "src/async/apiUtils";
// import { H1 } from "src/components/Shared/Text/Headers";
// import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

export default function Phoenix() {
  // const { useState, useEffect, useRef } = React;
  // const [isChartLoading, setIsChartLoading] = useState(true);

  // const DomoChart = ({ type, width, height, rows, columns, colors, properties }) => {
  //   const chartDiv = useRef(null);

  //   useEffect(() => {
  //     const options = {
  //       width: width || 900,
  //       height: height || 400,
  //       properties: properties,
  //       colors: colors,
  //     };
  //     const data = {
  //       rows: rows,
  //       columns: columns,
  //     };
  //     const chart = new Chart(type, data, options); // Assuming Chart is globally available
  //     chartDiv.current.appendChild(chart.canvas);
  //     chart.render();
  //     return () => {
  //       chartDiv.current.removeChild(chart.canvas);
  //     };
  //   }, [type, width, height, rows, columns, colors, properties]);

  //   return <div ref={chartDiv}></div>;
  // };

  // const Dashboard = ({ setIsChartLoading = () => {} }) => {
  //   const [formatData, setFormatData] = useState(null);

  //   // Assuming handleDomoCall is a function that fetches the data
  //   async function handleDomoCall() {
  //     // You should implement this function to fetch your data
  //     let pageId = 1824589741;
  //     const { data } = await apiRequest("get", `/phoenix-library/page/${pageId}`);
  //     return { cardsData: data.cardsData }; // Placeholder response
  //   }

  //   useEffect(() => {
  //     const fetchPages = async () => {
  //       let formatData = await handleDomoCall();
  //       setFormatData(formatData);
  //     };

  //     fetchPages();
  //     setIsChartLoading(false);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  //   return (
  //     <div className="h-auto w-auto bg-green-100">
  //       {!isChartLoading ? (
  //         formatData?.cardsData?.map((card) => {
  //           let { id, type, data } = card;
  //           let { columns, rows } = data;
  //           return (
  //             <div key={id}>
  //               <DomoChart
  //                 type={type}
  //                 rows={rows}
  //                 columns={columns}
  //               />
  //             </div>
  //           );
  //         })
  //       ) : (
  //         <Preloader />
  //       )}
  //     </div>
  //   );
  // };

  return (
    <div className="h-full w-full">
      {/* <H1>
        <div>
          <div className="flex">
            Phoenix Library -{" "}
            <p className="pl-2 font-light">
              <span className="bg-green-100 text-green-800 px-2 py-1 rounded">Autonomous</span> Builder
            </p>
          </div>
          <div className="text-base text-gray-400 font-normal">Demonstration only example.</div>
        </div>
      </H1>
      <div className="h-full w-full flex justify-center overflow-y-scroll">
        <Dashboard setIsChartLoading={setIsChartLoading} />
      </div> */}
    </div>
  );
}
