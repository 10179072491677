import { Bars3Icon, UsersIcon, WindowIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import Image from "src/components/Shared/Image";
import WarningModal from "src/components/Shared/WarningModal";
import { noFavicon } from "src/config/host";
import { isSVGString } from "src/helpers";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";

const GroupIconContainer = ({ icon = <UsersIcon className="h-6 w-6" />, count = 0 }) => {
  return (
    <div className="relative items-center rounded border-transparent px-2 py-2 text-sm text-gray-400 flex">
      {icon}
      <p className={classNames("absolute bottom-0 flex h-5 min-w-5 items-center justify-center rounded-full border-[1px] border-gray-100 bg-white p-1", count > 99 ? "-right-2" : "right-0")}>{count}</p>
    </div>
  );
};

const WorkspaceGroupHeader = ({ item, selectedGroup = {}, setSelectedGroup = () => { }, workspaceId, viewOnly, workspaceDetails, groupChanges, setGroupChanges = () => { }, draggableProps, dragHandleProps, innerRef, setListToggle, ...props }) => {
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

  return (
    <>
      <WarningModal
        isOpen={warningModalIsOpen}
        onSuccess={() => {
          setSelectedGroup(item);
          setWarningModalIsOpen(false);
          setGroupChanges(0);
        }}
        onCancel={() => setWarningModalIsOpen(false)}
      />
      <div
        className={classNames("relative flex h-16 w-full rounded-md bg-white shadow", item._id === selectedGroup?._id ? "" : "")}
        ref={innerRef}
        {...draggableProps}>
        {(authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["content_update"])) && !viewOnly && (
          <Button
            dragHandleProps={dragHandleProps}
            type="button"
            version="default"
            className="flex !h-full w-8 cursor-move items-center justify-center rounded-md rounded-r-none border border-gray-200 bg-gray-100 text-gray-700 outline-none ring-0">
            <Bars3Icon className="h-5 w-5 stroke-2" />
          </Button>
        )}
        <div
          onClick={groupChanges > 0 ? () => { setWarningModalIsOpen(true); setListToggle(true) } : () => { setSelectedGroup(item); setListToggle(true) }}
          className={classNames(
            "relative flex w-full max-w-full cursor-pointer items-center justify-between gap-2 rounded-md border sm:border-2 bg-white px-4 2xl:px-6 transition-all",
            item?._id === selectedGroup?._id ? "sm:border-highlightColor" : "border-gray-100 ",
            !viewOnly ? "rounded-l-none" : "",
          )}>
          <div className="relative flex w-full items-center gap-x-2">
            {item.image && (
              <div className="mb-2 mr-2 h-10 w-10 overflow-hidden p-1 py-2 flex-shrink-0">
                {isSVGString(item.image) ? (
                  <div dangerouslySetInnerHTML={{ __html: item.image }} />
                ) : (
                  <Image
                    image={item.image}
                    failImage={noFavicon}
                    alt={"Groups"}
                  />
                )}
              </div>
            )}
            <p className="max-w-[160px] sm:max-w-96 lgx:max-w-[120px] xl:max-w-[150px] truncate font-semibold text-gray-700">{item.name}</p>
          </div>
          <div className="flex items-center justify-end gap-x-1">
            {item.users?.length > 0 && !props?.userId && (
              <GroupIconContainer
                icon={<UsersIcon className="h-6 w-6" />}
                count={props?.userId ? item.users.filter((user) => user?._id === props?.userId)?.length : item.users?.length}
              />
            )}
            {item.users?.length > 0 && !props?.userId && item.permissions?.length > 0 && <div className="hidden h-[4px] w-[4px] rounded-full bg-gray-400 md:flex" />}
            {item.permissions?.length > 0 && (
              <GroupIconContainer
                icon={<WindowIcon className="h-6 w-6" />}
                count={item.permissions?.length}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(WorkspaceGroupHeader);
