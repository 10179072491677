import React from "react";
import { classNames } from "src/helpers/classNames";

const TextArea = ({ autoComplete = "on", rows = 3, label, name, disabled = false, value = "", error = false, type = "text", inputClassNames, labelClassNames = "", onChange = () => { }, onFocus = () => { }, onKeyDown = () => { }, placeholder = "", textType = "", inline = false }) => {
  const labelClasses = classNames(
    labelClassNames,
    "flex justify-between text-sm font-medium mb-1",
    error && "text-red-600",
    !inline && disabled && "cursor-not-allowed",
    inline && disabled && "bg-transparent"
  );
  const inputClasses = classNames(
    "min-h-[80px] block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor",
    error && "border-red-300",
    disabled ? "text-gray-400":"text-gray-700",
    !inline && disabled && "cursor-not-allowed",
    inline && disabled && "bg-transparent"
  );

  return (
    <div className={classNames(
      inline ? "relative h-full border rounded-md focus-within:border-highlightColor overflow-hidden px-0 pt-2 space-y-2" : "relative h-full",
      (inline && disabled) ? "bg-gray-50 border-gray-100" : "border-gray-300 shadow-sm"
      )}>
      <label
        htmlFor={name}
        className={inline ? classNames("m-0 pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3 flex justify-between", labelClasses) : labelClasses}>
        {label} <div className="pl-2">{error ? "*Required" : ""}</div>
      </label>
      <textarea
        disabled={disabled}
        className={classNames(inputClassNames ? inputClassNames : inputClasses, type === "code" ? "font-mono" : "", inline ? "py-0 border-none rounded-none shadow-none h-28 focus:border-none focus:ring-0 bg-transparent":"")}
        placeholder={placeholder}
        rows={rows}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
