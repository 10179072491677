import TableRow from "src/components/Shared/Table/TableRow";
import { formatDate } from "src/helpers/formatDate";
import {
  LoginContent,
  PageCreateContent,
  PageDeleteContent,
  PagePreviewContent,
  PageUpdateContent,
  PageViewContent,
  UserCreateContent,
  UserPasswordChangeContent,
  UserUpdateContent,
  WorkspaceCreateContent,
  UserSendResetPasswordEmailContent,
  UserSendWelcomeEmailContent,
  UserAssignToWorkspaceContent,
  UserUnAssignFromWorkspaceContent,
  UserDomoRoleChangeContent,
  UserIndividualPermissionChangeContent,
  UserAssignToGroupContent,
  UserUnAssignToGroupContent,
  UserDataAccessUpdateContent,
  UserAssignToAdminContent,
  AdminAssignToUserContent,
  UserSsoAutoUpdateDisableContent,
  UserSsoAutoUpdateEnableContent,
  UserDatasetAutoUpdateDisableContent,
  UserDatasetAutoUpdateEnableContent,
  UserStatusChangeToActiveContent,
  UserStatusChangeToInActiveContent,
  UserDeleteContent,
  UserDeleteFromWorkspaceContent,
  GroupCreateContent,
  GroupUpdateContent,
  GroupDeleteContent,
} from "./ActivityTypeContent";

function ActivityEntry({ activity, activityTypes }) {
  return (
    <TableRow keyId={activity?._id}>
      <td className="w-1/3 whitespace-pre-wrap break-all px-3 py-2 pl-4 text-gray-500 sm:table-cell sm:py-3 sm:pl-6 sm:text-sm md:w-1/5">
        {activity?.data?.created_by_name || activity?.data?.created_by_email || activity?.user_id ? (
          <>
            <div className="text-gray-900">{activity?.data?.created_by_name || activity?.user_id?.name || "N/A"}</div>
            <div className="text-gray-400">{activity?.data?.created_by_email || activity?.user_id?.email || "No email listed"}</div>
          </>
        ) : (
          <div className="text-gray-400">*User Deleted*</div>
        )}
      </td>
      <td className="hidden w-1/5 whitespace-pre-wrap break-all px-3 py-2 text-gray-500 sm:py-3 md:text-sm lg:table-cell">
        <div className="text-gray-900">
          {activityTypes?.find((type) => type?.key === activity?.type)?.value}
          <div className="text-gray-300">{activity?.data?.login_portal === "ADMIN_PORTAL" ? "Admin Portal" : "User Portal"}</div>
        </div>
      </td>
      <td className="break-word hidden w-1/3 whitespace-pre-wrap px-3 py-2 text-gray-500 sm:table-cell sm:py-3 sm:text-sm md:w-1/5">
        {activity?.type === "LOGIN" && <LoginContent activity={activity} />}
        {activity?.type === "PAGE_VIEW" && <PageViewContent activity={activity} />}
        {activity?.type === "WORKSPACE_CREATE" && <WorkspaceCreateContent activity={activity} />}
        {activity?.type === "PAGE_CREATE" && <PageCreateContent activity={activity} />}
        {activity?.type === "PAGE_UPDATE" && <PageUpdateContent activity={activity} />}
        {activity?.type === "PAGE_PREVIEW" && <PagePreviewContent activity={activity} />}
        {activity?.type === "PAGE_DELETE" && <PageDeleteContent activity={activity} />}
        {activity?.type === "USER_CREATE" && <UserCreateContent activity={activity} />}
        {activity?.type === "USER_UPDATE" && <UserUpdateContent activity={activity} />}
        {activity?.type === "USER_PASSWORD_CHANGE" && <UserPasswordChangeContent activity={activity} />}
        {activity?.type === "USER_SEND_RESET_PASSWORD_EMAIL" && <UserSendResetPasswordEmailContent activity={activity} />}
        {activity?.type === "USER_SEND_WELCOME_EMAIL" && <UserSendWelcomeEmailContent activity={activity} />}
        {activity?.type === "USER_ASSIGN_TO_WORKSPACE" && <UserAssignToWorkspaceContent activity={activity} />}
        {activity?.type === "USER_UNASSIGN_FROM_WORKSPACE" && <UserUnAssignFromWorkspaceContent activity={activity} />}
        {activity?.type === "USER_DOMO_ROLE_CHANGE" && <UserDomoRoleChangeContent activity={activity} />}
        {activity?.type === "USER_INDIVIDUAL_PERMISSION_UPDATE" && <UserIndividualPermissionChangeContent activity={activity} />}
        {activity?.type === "USER_ASSIGN_TO_GROUP" && <UserAssignToGroupContent activity={activity} />}
        {activity?.type === "USER_UNASSIGN_TO_GROUP" && <UserUnAssignToGroupContent activity={activity} />}
        {activity?.type === "USER_DATA_ACCESS_UPDATE" && <UserDataAccessUpdateContent activity={activity} />}
        {activity?.type === "USER_ASSIGN_TO_ADMIN" && <UserAssignToAdminContent activity={activity} />}
        {activity?.type === "ADMIN_ASSIGN_TO_USER" && <AdminAssignToUserContent activity={activity} />}
        {activity?.type === "USER_SSO_AUTO_UPDATE_DISABLE" && <UserSsoAutoUpdateDisableContent activity={activity} />}
        {activity?.type === "USER_SSO_AUTO_UPDATE_ENABLE" && <UserSsoAutoUpdateEnableContent activity={activity} />}
        {activity?.type === "USER_DATASET_AUTO_UPDATE_DISABLE" && <UserDatasetAutoUpdateDisableContent activity={activity} />}
        {activity?.type === "USER_DATASET_AUTO_UPDATE_ENABLE" && <UserDatasetAutoUpdateEnableContent activity={activity} />}
        {activity?.type === "USER_STATUS_CHANGE_TO_ACTIVE" && <UserStatusChangeToActiveContent activity={activity} />}
        {activity?.type === "USER_STATUS_CHANGE_TO_INACTIVE" && <UserStatusChangeToInActiveContent activity={activity} />}
        {activity?.type === "USER_DELETE" && <UserDeleteContent activity={activity} />}
        {activity?.type === "USER_DELETE_FROM_WORKSPACE" && <UserDeleteFromWorkspaceContent activity={activity} />}
        {activity?.type === "GROUP_CREATE" && <GroupCreateContent activity={activity} />}
        {activity?.type === "GROUP_UPDATE" && <GroupUpdateContent activity={activity} />}
        {activity?.type === "GROUP_DELETE" && <GroupDeleteContent activity={activity} />}
      </td>
      <td className="w-1/4 whitespace-pre-wrap break-all px-3 py-2 text-gray-500 sm:table-cell sm:py-3 sm:text-sm lg:w-1/5">
        <div className="hidden text-gray-900 2xl:block">{formatDate(activity?.created_at, "LLLL")}</div>
        <div className="flex flex-col text-gray-900 2xl:hidden">
          {formatDate(activity?.created_at, "MM/DD/YYYY")} <span className="text-gray-400">{formatDate(activity?.created_at, "h:mm:ssA")}</span>
        </div>
      </td>
    </TableRow>
  );
}

export default ActivityEntry;
