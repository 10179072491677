import { classNames } from "src/helpers/classNames";

const RadioGroup = ({
  title = "Radio Group",
  subtitle = "Which of these options do you prefer?",
  options = [
    { id: "yes", title: "Yes" },
    { id: "no", title: "No" },
  ],
  defaultChecked = "yes",
  onChange = () => {},
  layout = "veritcal",
}) => {
  return (
    <div>
      <label className="text-base font-medium text-gray-700">{title}</label>
      <p className="text-sm text-gray-400">{subtitle}</p>
      <fieldset className="mt-4">
        <legend className="sr-only">
          {title} - {subtitle}
        </legend>
        <div className={classNames("space-y-4", layout === "horizontal" ? "sm:flex sm:items-center sm:space-x-10 sm:space-y-0" : "")}>
          {options.map((option) => (
            <div
              key={option.id}
              className="flex items-center">
              <input
                id={option.id}
                name="option-method"
                type="radio"
                defaultChecked={option.id === defaultChecked}
                value={option.id}
                onChange={(e) => onChange(e.target.value)}
                className="h-4 w-4 border-gray-300 text-highlightColor focus:ring-highlightColor"
              />
              <label
                htmlFor={option.id}
                className="ml-3 block text-sm font-medium leading-6 text-gray-600">
                {option.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default RadioGroup;
