import React, { useEffect } from "react";
import CustomHtmlEmbed from "./CustomHtmlEmbed";
import LinkEmbed from "./LinkEmbed";

export default function BasicRouter({ content, config, setError }) {
  useEffect(() => {
    if (!content?.config?.provider) {
      setError("Provider type does not exist.");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.config?.provider]);

  return (
    <>
      {config?.type === "CUSTOM_HTML" && <CustomHtmlEmbed content={content} />}
      {config?.type === "EXTERNAL_LINK" && <LinkEmbed content={content} />}
    </>
  );
}
