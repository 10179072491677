import { PlusIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWorkspaceData } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import WorkspaceEntry from "src/components/Workspaces/WorkspaceEntry";
import WorkspacesAddModal from "src/components/Workspaces/WorkspacesModals/WorkspacesAddModal";
import useWindowDimensions from "src/hooks/useWindowDimensions";

const WorkspaceList = ({ user, viewOnly = false }) => {
  const navigate = useNavigate();

  const [meta, setMeta] = useState(null);
  const [workspacesLoaded, setWorkspacesLoaded] = useState(false);

  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [showAddModal, setShowAddModal] = useState(false);
  const [workspaceType, setWorkspaceType] = useState("");
  const [workspaceList, setWorkspaceList] = useState([]);
  const [isDragging, setIsDragging] = useState({ keyId: "", value: false });
  const { width } = useWindowDimensions();

  const getWorkspaceList = useCallback(async () => {
    try {
      setWorkspacesLoaded(false);
      const res = await apiRequest("post", `workspaces/list`, {
        body: { includes: ["groups", "users", "pages"], limit: limit || 50, offset: offset || 0, keyword: keyword || "" },
      });

      if (res.data.status === 200) {
        setWorkspaceList(res.data.data || []);
        setMeta(res.data.meta);
      } else {
        setWorkspaceList([]);
        setMeta(null);
      }
      setWorkspacesLoaded(true);
    } catch (error) {}
  }, [limit, offset, keyword]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    getWorkspaceList();
  }, [getWorkspaceList]);

  if (!user?._id) return <></>;

  if (user?.type === "editor" && user?.workspaces?.length === 1) {
    navigate(`/workspaces/${user.workspaces[0]._id}`);
    return <></>;
  }

  const updateWorkspaceOrdering = async (newWorkspace) => {
    try {
      await apiRequest("post", `/workspaces/ordering/manage`, {
        body: {
          workspaces: newWorkspace.map((wrk) => wrk._id),
          offset,
        },
      });
    } catch (error) {
      console.dir("ERROR:", error);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }

    const newWorkspace = reorder(workspaceList, result.source.index, result.destination.index);
    setWorkspaceList(newWorkspace);
    setIsDragging({ keyId: "", value: false });
    updateWorkspaceOrdering(newWorkspace);
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0);
              setKeyword(val);
            }}
            placeholder={"Search"}
            inline={true}
          />
        }
        rightContent={
          workspacesLoaded &&
          user?.type === "admin" && (
            <Button
              version="secondary"
              hoverText="Add a workspace"
              onClick={() => {
                setWorkspaceType("IFRAME_EMBED");
                setShowAddModal(true);
              }}>
              <PlusIcon className="h-4 w-4 stroke-[3px]" />
              {width > 640 ? "Add workspace" : "Workspace"}
            </Button>
          )
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="w-[3%] rounded-tl-lg py-3.5 text-left text-sm font-semibold text-gray-900"></th>
            <th
              scope="col"
              className="w-[52%] rounded-tl-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="w-[15%] px-3 py-3.5 text-left text-[0] font-semibold text-gray-900 sm:text-sm">
              Pages
            </th>
            <th
              scope="col"
              className="w-[15%] px-3 py-3.5 text-left text-[0] font-semibold text-gray-900 sm:text-sm">
              Groups
            </th>
            <th
              scope="col"
              className="w-[15%] rounded-tr-lg px-3 py-3.5 text-left text-[0] font-semibold text-gray-900 sm:text-sm">
              Users
            </th>
          </TRHeader>
        }
        colSpan="5"
        loaded={workspacesLoaded}
        dataExists={workspaceList?.length > 0}
        listData={workspaceList}
        listDraggableEnabled
        onDragStart={(e) => setIsDragging({ keyId: e.draggableId, value: true })}
        onDragEnd={onDragEnd}
        listComponent={(props) => (
          <WorkspaceEntry
            {...props}
            viewOnly={viewOnly}
            isDragging={isDragging}
          />
        )}>
        {workspaceList.map((workspace, index) => (
          <WorkspaceEntry
            viewOnly={viewOnly}
            key={workspace._id}
            item={workspace}
          />
        ))}
      </Table>
      <PaginationFooter
        itemName="Workspace record"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />

      <WorkspacesAddModal
        isOpen={showAddModal}
        workspaceType={workspaceType}
        onCancel={() => setShowAddModal(false)}
        onSuccess={() => {
          setShowAddModal(false);
          getWorkspaceList();
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { getWorkspaceData })(WorkspaceList);
