import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import { H3 } from "src/components/Shared/Text/Headers";
import Button from "src/components/Shared/Buttons/Button";

const EditContainer = ({
  title,
  subtitle = "",
  preview,
  height = "4000px",
  onCancel = () => {},
  onSuccess = () => {},
  defaultOptions = null,
  isLoading = false,
  submitIsLoading = false,
  submitIsDisabled = false,
  fullWidth = false,
  defaultOpen = false,
  borderBottom = true,
  children,
}) => {
  const [show, setShow] = useState(defaultOpen);

  const [options, setOptions] = useState({
    onCancelButtonVisible: true,
    onCancelButtonText: "Cancel",
    onSuccessButtonVisible: true,
    onSuccessButtonText: "Save",
    onSuccessLoaderVisible: false,
    onSuccessLoaderStart: false,
  });

  useEffect(() => {
    if (defaultOptions) setOptions({ ...options, ...defaultOptions });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  useEffect(() => {
    if (isLoading) {
      setOptions((prev) => ({ ...prev, onSuccessLoaderStart: isLoading, onSuccessLoaderVisible: isLoading }));
    } else {
      setTimeout(() => {
        setOptions((prev) => ({ ...prev, onSuccessLoaderStart: isLoading, onSuccessLoaderVisible: isLoading }));
      }, 300);
    }
  }, [isLoading]);

  return (
    <div className={classNames("w-full py-6 transition-all sm:py-10", borderBottom ? "border-b border-slate-200" : "")}>
      <div className="flex flex-shrink-0 items-center justify-between gap-10">
        <div className="flex flex-grow">
          <div className="w-64 flex-shrink-0 lg:w-96 xl:w-[500px]">
            <H3 wrapperClass="my-0">{title}</H3>
            {subtitle && <div className="text-sm font-medium text-gray-400">{subtitle}</div>}
          </div>
          <div className={classNames("hidden flex-shrink flex-grow text-gray-400 transition-all md:block", show ? `max-h-[${height}] pt-4` : "max-h-auto overflow-hidden pt-1")}>{show ? !fullWidth && children : preview}</div>
        </div>
        <Button
          type="button"
          version="default"
          onClick={() => setShow(!show)}
          className="flex h-8 w-16 flex-shrink-0 items-center justify-center text-lg font-bold text-highlightColor transition-all hover:underline hover:opacity-80 focus:border-2 focus:border-dashed focus:border-gray-300">
          {show ? "Close" : "Edit"}
        </Button>
      </div>
      <div className={classNames("text-gray-400 transition-all", show && fullWidth ? `block w-full max-h-[${height}]` : `md:hidden md:pl-4 max-h-[${height}] md:max-h-auto md:overflow-hidden md:pt-0`)}>{show && children}</div>
      {show && (options.onSuccessButtonVisible || options.onCancelButtonVisible) ? (
        <div className="flex flex-shrink-0 justify-end gap-x-4 py-4">
          {options.onSuccessButtonVisible && (
            <Button
              type="button"
              version="default"
              disabled={(options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)}
              className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm ${
                !((options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)) ? "!bg-highlightColor" : "bg-gray-50 !text-gray-500"
              }`}
              onClick={() => {
                onSuccess();
                if (!defaultOpen) {
                  setShow(false);
                }
              }}>
              {options.onSuccessButtonText}
              {options.onSuccessLoaderVisible && options.onSuccessLoaderStart && !options.onCancelLoaderVisible && (
                <ArrowPathIcon className={`ml-2 h-5 w-5 animate-spin text-white ${!((options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)) ? "text-white" : "!text-gray-500"}`} />
              )}
            </Button>
          )}
          {options.onCancelButtonVisible && (
            <Button
              disabled={(options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)}
              type="button"
              version="default"
              className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => {
                onCancel();
                if (!defaultOpen) {
                  setShow(false);
                }
              }}>
              {options.onCancelButtonText}
              {options.onCancelLoaderVisible && options.onCancelLoaderStart && <ArrowPathIcon className="ml-2 h-5 w-5 animate-spin" />}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default EditContainer;
