import { PlusIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { workspaceIntegrationFilterList, workspaceIntegrationFilterManage } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import useFetch from "src/hooks/useFetch";

const WorkspaceIntegrationFilters = ({ user, workspaceId, ...props }) => {
  const [filters, setFilters] = useState([]);
  const [mainFilter, setMainFilters] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const {
    response: { data: operators },
  } = useFetch("/operators/list");

  const getFilters = async () => {
    try {
      const data = await props.workspaceIntegrationFilterList({ workspace_id: workspaceId });
      setFilters(data);
      setMainFilters(data);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFilter = async () => {
    setFilters([
      ...filters,
      {
        operator_id: null,
        column_name: null,
        value_type: "value",
        column_value: null,
        trusted_attribute: null,
        datasource_id: null,
      },
    ]);
  };

  const handleOnSave = async () => {
    try {
      setDisabled(true);
      const res = await props.workspaceIntegrationFilterManage({ filters, workspace_id: workspaceId });
      toast.success(res.message);
      setDisabled(false);
      setMainFilters(filters);
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setCustomFields(resData.data);
        }
      } catch (err) {
        console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map((field) => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label,
    })),
  ];

  const filterButton = (
    <Button
      version="default"
      onClick={addFilter}
      className="!h-10 text-highlightColor">
      <div className="flex items-center gap-x-3">
        <PlusIcon className="h-5 w-5 stroke-2" /> Add additional filter
      </div>
    </Button>
  );

  return (
    <EditContainer
      title="Workspace Filters"
      subtitle="Programatically filter all workspace cards and dashboards"
      preview={
        <div className="flex h-10 w-56 items-center">
          <div className="flex gap-x-2 rounded border border-gray-100 px-3 py-1 shadow">
            {filters.length ? (
              <>
                <div>{filters.length}</div>
                Applied Filter
                {filters.length > 1 && "s"}
              </>
            ) : (
              "No applied filters"
            )}
          </div>
        </div>
      }
      isLoading={disabled}
      onSuccess={handleOnSave}
      onCancel={() => setFilters(mainFilter)}
      defaultOptions={{
        onCancelButtonVisible: true,
        onSuccessButtonVisible: true,
      }}
      fullWidth={true}>
      <>
        <div className="relative">
          <p className="mb-2">Manage the workspace's filters from one location.</p>
          <div className="mt-2 space-y-3 rounded bg-white pb-3">
            {filters.length > 0 ? (
              <>
                {filters.map((filter, index) => {
                  return (
                    <WorkspaceIntegrationFiltersItem
                      from={"workspaceSettings"}
                      key={index}
                      filters={filters}
                      user={user}
                      workspaceId={workspaceId}
                      state={filter}
                      operators={operators}
                      trustedAttributeArr={allTrustedAttributes}
                      setState={(e) => {
                        setFilters(
                          filters.map((fl, i) => {
                            if (index === i) {
                              return e;
                            } else {
                              return fl;
                            }
                          }),
                        );
                      }}
                      removeFilter={() => {
                        setFilters((prevData) => prevData.filter((data, i) => i !== index));
                      }}
                    />
                  );
                })}
                <div className="flex">{filterButton}</div>
              </>
            ) : (
              <NoDataDashedContainer addButton={filterButton} />
            )}
          </div>
        </div>
      </>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspace: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { workspaceIntegrationFilterList, workspaceIntegrationFilterManage })(WorkspaceIntegrationFilters);
