import { GlobeAltIcon } from "@heroicons/react/24/outline";
import WorkspaceSubscriberInstance from "../Workspaces/WorkspaceSubscriberInstance";

const LoginContent = ({ activity }) => {
  return <span className="text-gray-300">Authentication</span>;
};

const PageViewContent = ({ activity }) => {
  return activity?.data?.workspace_name || activity?.data?.workspace_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.page_name || activity?.data?.page_id?.name ? (
        activity?.data?.page_name || activity?.data?.page_id?.name
      ) : (
        <div className="items-center text-gray-400">{activity?.data?.workspace_id?.workspace_type === "JWT_FULL_EMBED" ? <WorkspaceSubscriberInstance /> : "*Page Deleted*"}</div>
      )}
      <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>
    </div>
  ) : (
    <div className="text-gray-400">*Workspace Deleted*</div>
  );
};

const WorkspaceCreateContent = ({ activity }) => {
  return activity?.data?.workspace_name || activity?.data?.workspace_id?.name ? (
    <div className="flex flex-col text-gray-900">
      <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>
    </div>
  ) : (
    <div className="text-gray-400">*Workspace Deleted*</div>
  );
};

const PageCreateContent = ({ activity }) => {
  return activity?.data?.page_name || activity?.data?.page_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.page_name || activity?.data?.page_id?.name}
      <span className="text-gray-300">
        {activity?.data?.workspace_name || activity?.data?.workspace_id?.name || (
          <div className="flex space-x-2">
            <GlobeAltIcon className="h-4 w-4  sm:h-5 sm:w-5" /> Global
          </div>
        )}
      </span>
    </div>
  ) : (
    <div className="text-gray-400">*Page Deleted*</div>
  );
};

const PageUpdateContent = ({ activity }) => {
  return activity?.data?.page_name || activity?.data?.page_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.page_name || activity?.data?.page_id?.name}
      <span className="text-gray-300">
        {activity?.data?.workspace_name || activity?.data?.workspace_id?.name || (
          <div className="flex space-x-2">
            <GlobeAltIcon className="h-4 w-4  sm:h-5 sm:w-5" /> Global
          </div>
        )}
      </span>
    </div>
  ) : (
    <div className="text-gray-400">*Page Deleted*</div>
  );
};

const PagePreviewContent = ({ activity }) => {
  return activity?.data?.page_name || activity?.data?.page_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.page_name || activity?.data?.page_id?.name}
      <span className="text-gray-300">
        {activity?.data?.workspace_id ? (
          <>
            {activity?.data?.group_id && !activity?.data?.user_id && "Workspace's group Level"}
            {!activity?.data?.group_id && activity?.data?.user_id && "User's individual Level"}
            {!activity?.data?.group_id && !activity?.data?.user_id && "Workspace's page Level"}
          </>
        ) : activity?.data?.group_id ? (
          "Group Level"
        ) : (
          "Page Level"
        )}
        {/* {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && (activity?.data?.group_name || activity?.data?.group_id?.name) && "Workspace group Level"}
        {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && !activity?.data?.group_name && !activity?.data?.group_id?.name && "Workspace Level"} */}
      </span>
    </div>
  ) : (
    <div className="text-gray-400">*Page Deleted*</div>
  );
};

const PageDeleteContent = ({ activity }) => {
  return activity?.data?.page_name || activity?.data?.page_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.page_name || activity?.data?.page_id?.name}
      <span className="text-gray-300">
        {activity?.data?.workspace_name || activity?.data?.workspace_id?.name || (
          <div className="flex space-x-2">
            <GlobeAltIcon className="h-4 w-4  sm:h-5 sm:w-5" /> Global
          </div>
        )}
      </span>
    </div>
  ) : (
    <div className="text-gray-400">*Page Deleted*</div>
  );
};

const UserCreateContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserUpdateContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserPasswordChangeContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserSendResetPasswordEmailContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserSendWelcomeEmailContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserAssignToWorkspaceContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserUnAssignFromWorkspaceContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserDomoRoleChangeContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserIndividualPermissionChangeContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserAssignToGroupContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.group_name || activity?.data?.group_id?.name) && <span className="text-gray-300">{activity?.data?.group_name || activity?.data?.group_id?.name}</span>}
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserUnAssignToGroupContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.group_name || activity?.data?.group_id?.name) && <span className="text-gray-300">{activity?.data?.group_name || activity?.data?.group_id?.name}</span>}
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserDataAccessUpdateContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserAssignToAdminContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const AdminAssignToUserContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserSsoAutoUpdateDisableContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserSsoAutoUpdateEnableContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserDatasetAutoUpdateDisableContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserDatasetAutoUpdateEnableContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserStatusChangeToActiveContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserStatusChangeToInActiveContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserDeleteContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const UserDeleteFromWorkspaceContent = ({ activity }) => {
  return activity?.data?.user_name || activity?.data?.user_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.user_name || activity?.data?.user_id?.name}
      <span className="text-gray-300">{activity?.data?.user_email || activity?.data?.user_id?.email}</span>
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*User Deleted*</div>
  );
};

const GroupCreateContent = ({ activity }) => {
  return activity?.data?.group_name || activity?.data?.group_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.group_name || activity?.data?.group_id?.name}
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*Group Deleted*</div>
  );
};

const GroupUpdateContent = ({ activity }) => {
  return activity?.data?.group_name || activity?.data?.group_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.group_name || activity?.data?.group_id?.name}
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*Group Deleted*</div>
  );
};

const GroupDeleteContent = ({ activity }) => {
  return activity?.data?.group_name || activity?.data?.group_id?.name ? (
    <div className="flex flex-col text-gray-900">
      {activity?.data?.group_name || activity?.data?.group_id?.name}
      {(activity?.data?.workspace_name || activity?.data?.workspace_id?.name) && <span className="text-gray-300">{activity?.data?.workspace_name || activity?.data?.workspace_id?.name}</span>}
    </div>
  ) : (
    <div className="text-gray-400">*Group Deleted*</div>
  );
};

export {
  LoginContent,
  PageViewContent,
  WorkspaceCreateContent,
  PageCreateContent,
  PageUpdateContent,
  PagePreviewContent,
  PageDeleteContent,
  UserCreateContent,
  UserUpdateContent,
  UserPasswordChangeContent,
  UserSendResetPasswordEmailContent,
  UserSendWelcomeEmailContent,
  UserAssignToWorkspaceContent,
  UserUnAssignFromWorkspaceContent,
  UserDomoRoleChangeContent,
  UserIndividualPermissionChangeContent,
  UserAssignToGroupContent,
  UserUnAssignToGroupContent,
  UserDataAccessUpdateContent,
  UserAssignToAdminContent,
  AdminAssignToUserContent,
  UserSsoAutoUpdateDisableContent,
  UserSsoAutoUpdateEnableContent,
  UserDatasetAutoUpdateDisableContent,
  UserDatasetAutoUpdateEnableContent,
  UserStatusChangeToActiveContent,
  UserStatusChangeToInActiveContent,
  UserDeleteContent,
  UserDeleteFromWorkspaceContent,
  GroupCreateContent,
  GroupUpdateContent,
  GroupDeleteContent,
};
